import React, { useState } from 'react';
import { RootState } from 'handlers';
import { useSelector } from 'react-redux';

import { HardOfferData } from 'handlers/applicationData';
import CheckboxSmall from 'components/Checkbox/CheckboxSmall';
import Button from 'components/Button';
import FormNavigation from 'components/FormNavigation';
import { FlowComponentType } from 'routes/types';
import useDispatchWithUnwrap from 'hooks/useDispatchWithUnwrap';
import { sendOfferComparisonEmail } from 'thunks';
import { getApplicationData } from 'selectors/getApplicationData';
import { getCardData } from 'selectors/getCardData';
import { ButtonType } from 'components/Button/Button';
import { PayOffResult } from 'enums/FlowNextResults';

import { ApplicationStatusName } from 'enums/ApplicationStatusName';

import LoanSummary from './components/LoanSummary';
import DebtConsolidationDropdown from './components/DebtConsolidationDropdown';
import CompareSavings from './components/CompareSavings';

import styles from './PayOffPlan.module.scss';

const PayOffPlan = ({ navigationInfo, handleNext }: FlowComponentType): JSX.Element => {
  const dispatchWithUnwrap = useDispatchWithUnwrap();

  const { application, isLoading } = useSelector(getApplicationData);
  const { applied: cardApplied } = useSelector(getCardData);

  const [sendEmail, setSendEmail] = useState<boolean>(true);
  const offerComparisonEmailLoading = useSelector((state: RootState) => state.emailService.offerComparison.isLoading);

  const hardOffer = application?.hardOffer as HardOfferData;

  const { planneryLoan, payOffTotal } = hardOffer;
  const { loanAmount, monthsSaved, moneySaved } = hardOffer.offerSummary;

  const handleAction = async () => {
    if (application!.status === ApplicationStatusName.ManualReview) {
      handleNext(PayOffResult.ManualReview);
      return;
    }

    if (sendEmail) {
      dispatchWithUnwrap(sendOfferComparisonEmail(application!.id));
      handleNext(PayOffResult.EmailSent);
    } else {
      handleNext(PayOffResult.Continue);
    }
  };

  const handleChange = () => {
    setSendEmail(!sendEmail);
  };

  return (
    <>
      <FormNavigation {...navigationInfo} />
      <div className={styles.container}>
        <div className={styles.title}>Here's how we can maximize your savings.</div>

        {/* Dropdown */}
        <DebtConsolidationDropdown data={hardOffer} maxLoanAmount={application!.maxLoanAmount} />

        {/* Compare Savings */}
        <div className={styles.compareSavingsContainer}>
          <div className={styles.heading}>2. Compare your savings</div>

          <div className={styles.summaryWrapper}>
            <div className={styles.vs}>vs</div>

            <CompareSavings
              currentDebt={payOffTotal}
              planneryLoan={planneryLoan}
              hasOriginationFee={!!application?.originationFee}
            />
          </div>

          <div className={styles.loanSummaryContainer}>
            <LoanSummary loanAmount={loanAmount} monthsSaved={monthsSaved} moneySaved={moneySaved} />
          </div>
        </div>

        {/* Get email copy */}
        <CheckboxSmall
          checked={sendEmail}
          onChange={handleChange}
          className={styles.checkbox}
          label="3. Get a copy of this plan"
          disabled={isLoading}
        />

        <Button
          isLoading={offerComparisonEmailLoading || isLoading}
          className={styles.nextButton}
          onClick={handleAction}
        >
          Next
        </Button>
        {cardApplied && (
          <Button
            type={ButtonType.Inverted}
            className={styles.buttonMarginTop}
            onClick={() => {
              handleNext(PayOffResult.BackToYourFinances);
            }}
          >
            Not Now
          </Button>
        )}

        <div className={styles.makeChanges}>
          <div className={styles.changesLabel}>Want to make changes to this plan?</div>
          <div className={styles.changesLabel}>
            Email us at{' '}
            <a href="mailto:support@plannery.com" className={styles.contactUs}>
              support@planneryapp.com
            </a>
          </div>
          <div className={styles.changesLabel}>
            Call/text us at{' '}
            <a href="tel:404-205-8882" className={styles.contactUs}>
              (404) 205-8882
            </a>
          </div>
        </div>
      </div>
    </>
  );
};
export default PayOffPlan;
