import React, { FC } from 'react';
import clsx from 'clsx';
import { formatMonetaryAmount } from 'utils/formatMonetaryAmount';
import { ReactComponent as CheckIcon } from 'images/check-icon.svg';
import { HardOfferSummary } from 'handlers/applicationData';
import { RootState } from 'handlers';
import { useSelector } from 'react-redux';
import Loader from 'components/Loader';

import styles from './LoanSummary.module.scss';

const LoanSummary: FC<HardOfferSummary> = ({ loanAmount, moneySaved, monthsSaved }) => {
  const { isLoading } = useSelector((state: RootState) => state.applicationData);

  return (
    <div className={styles.loanSummary}>
      <div className={clsx(styles.summaryCard, styles.centerAligned)}>
        {isLoading ? (
          <Loader color="#9d86f9" size={68} />
        ) : (
          <div className={styles.summaryText}>
            Consolidate your debt with your{' '}
            <span className={styles.highlighted}>{formatMonetaryAmount(loanAmount)}</span> loan
          </div>
        )}
      </div>
      <div className={styles.summaryCard}>
        {isLoading ? (
          <Loader color="#9d86f9" size={68} />
        ) : (
          <>
            {moneySaved > 0 && (
              <div className={styles.summaryCheck}>
                <CheckIcon className={styles.checkIcon} />
                <div className={styles.summaryTextContent}>
                  <div className={styles.checkText}>Save money</div>
                  <div className={styles.checkAmount}>{formatMonetaryAmount(moneySaved)}</div>
                </div>
              </div>
            )}

            {monthsSaved > 0 && (
              <div className={styles.summaryCheck}>
                <CheckIcon className={styles.checkIcon} />
                <div className={styles.summaryTextContent}>
                  <div className={styles.checkText}>Save time</div>
                  <div className={styles.checkAmount}>{monthsSaved} months</div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default LoanSummary;
